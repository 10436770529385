import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const List = makeShortcode("List");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-jamming-on-value",
      "style": {
        "position": "relative"
      }
    }}>{`⚖ Jamming On Value`}<a parentName="h1" {...{
        "href": "#-jamming-on-value",
        "aria-label": " jamming on value permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "what-is-value",
      "style": {
        "position": "relative"
      }
    }}>{`What is Value`}<a parentName="h2" {...{
        "href": "#what-is-value",
        "aria-label": "what is value permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`This is, in a sense, the ultimate question. So, how can we use our new thinking skills based on complementary opposites to break it down and come to some useful insights?`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Without tracing the whole history, one can see `}<strong parentName="p">{`how our ability to create value has always been tied to the ways in which we tell stories about, and with, our shared records`}</strong>{`. `}</p>
    </blockquote>
    <p>{`As we play around with the concept of value, thinking in `}<strong parentName="p">{`complementary opposites`}</strong>{` makes our understanding more complete. `}</p>
    <p>{`We've previously defined `}<em parentName="p">{`value`}</em>{` in the context of `}<a parentName="p" {...{
        "href": "/learn/module-1/value/#consensus"
      }}><strong parentName="a">{`consensus`}</strong></a>{` and `}<a parentName="p" {...{
        "href": "/learn/module-1/value/#narrative"
      }}><strong parentName="a">{`narrative`}</strong></a>{`. Alternatively, we can also view `}<em parentName="p">{`value`}</em>{` from an economist's lens, leading us to other terms such as `}<strong parentName="p">{`goods`}</strong>{` and `}<strong parentName="p">{`scarcity`}</strong>{`.`}</p>
    <h2 {...{
      "id": "in-a-virtual-world",
      "style": {
        "position": "relative"
      }
    }}>{`In a Virtual World`}<a parentName="h2" {...{
        "href": "#in-a-virtual-world",
        "aria-label": "in a virtual world permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p><strong parentName="p">{`Scarcity`}</strong>{` of `}<strong parentName="p">{`goods`}</strong>{` is a central concept of economics. It drives economic processes such as trade, production, and consumption. These processes in turn affect social behavior, determining how trust is built in our communities. However,`}</p>
    <blockquote>
      <p parentName="blockquote">{`🌍 In a virtual world, the cost of production of a virtual good is effectively ZERO.`}</p>
    </blockquote>
    <p>{`Before computer games introduced the concept of virtual goods, this was unheard of. Physical goods ALWAYS had a production cost. With virtual goods, all it takes is a few processing cycles in a computer to create an item.`}</p>
    <p>{`If there's no cost to produce a good, then `}<em parentName="p">{`how can it be scarce`}</em>{`?`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 The scarcity of virtual goods is just a shared fiction.`}</p>
    </blockquote>
    <p>{`This foundational change necessitates rethinking our economics. What happens to our well-balanced equations when a variable suddenly disappears? How will market forces adapt when supply is infinite?`}</p>
    <p>{`Fortunately, Lehdonvirta and Castranova dig deep into these concepts in `}<a parentName="p" {...{
        "href": "/track-gaming/module-0/curated#virtual-economies-design-and-analysis"
      }}>{`Virtual Economies: Design and Analysis`}</a>{`. The whole book is a rich space for understanding value, and should be required reading for any web 3 builder. `}</p>
    <p><img parentName="p" {...{
        "src": "/images/gaming-img/virtualgoods.png",
        "alt": "Virtual Goods"
      }}></img></p>
    <p>{`The important points the book mostly circle back to the attention economy:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Today, at least in the world of online games and digital services, the scarcest resources are no longer food and shelter, but human attention. In the pursuit of this resource, even the economy itself needs to be fashioned into a consumable good.`}</p>
    </blockquote>
    <h2 {...{
      "id": "of-metaverses",
      "style": {
        "position": "relative"
      }
    }}>{`Of Metaverses`}<a parentName="h2" {...{
        "href": "#of-metaverses",
        "aria-label": "of metaverses permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The quote ends with a salient point: with human attention being so finite, any participation in virtual economies has to be compelling.`}</p>
    <p>{`The most successful game developers recognize this. They are the ones leading the race to realize the `}<a parentName="p" {...{
        "href": "/track-gaming/module-1/crafted/#of-mirrorworlds-and-metaverses"
      }}>{`Metaverse / Mirrorworld`}</a>{` vision -- one platform that will capture the most value. `}</p>
    <p>{`Each developer has their own strategy: Fortnite does massive cross-overs with music and film; Roblox banks on user-generated content; and Minecraft relies on AR and cross-licensing to other games. Their attempts have been largely successful, generating billions of dollars in revenue and creating a similarly massive economy of their virtual goods.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/gaming-img/metaverse.png",
        "alt": "Metaverse"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`👀 More importantly, these games have become part of popular culture, commanding the attention of hundreds of millions of players.`}</p>
    </blockquote>
    <p>{`As Web 3 game developers, we need to realize that `}<a parentName="p" {...{
        "href": "/track-gaming/module-1/crafted/#industry-references"
      }}>{`these are just some of the forces`}</a>{` shaping the Metaverse right now. If human attention is the most valuable resource, how will we fare against these meticulously designed games? If the only use-case for an NFT is for speculation, and we're competing against virtual goods with established utility and network effects, how relevant will our NFTs be in future iterations of the Metaverse?`}</p>
    <h2 {...{
      "id": "and-nfts",
      "style": {
        "position": "relative"
      }
    }}>{`And NFTs`}<a parentName="h2" {...{
        "href": "#and-nfts",
        "aria-label": "and nfts permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We have at least planted the seeds of innovation. From Virtual Economies:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Economic processes such as production, logistics, and trade are not simply means towards ends but can also be fun in themselves.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Game content does not always have to be inspired by adventure and combat. It can also be about trade and industry.`}</p>
    </blockquote>
    <p>{`The NFT standard allowed Web 3 games to create exactly this kind of content. Through it we've discovered new gameplay primitives centered around trade: breeding NFTs, collecting crypto art, turning a profit from staking. `}</p>
    <p>{`Moving forward we need to look beyond trade, into other areas where Web 3 can provide value. For example, there might be nuggets of gameplay we can find from user-driven governance in DAOs.`}</p>
    <p>{`It's these primitives that will allow Web 3 games to compete asymmetrically against the incumbents. To truly shape the Metaverse, we need to discover a few more. `}</p>
    <h2 {...{
      "id": "lets-jam",
      "style": {
        "position": "relative"
      }
    }}>{`Let's Jam`}<a parentName="h2" {...{
        "href": "#lets-jam",
        "aria-label": "lets jam permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Web 3 fortunately follows an open-source ethos, and we can refer to `}<a parentName="p" {...{
        "href": "/track-gaming/module-1/curated/#web-3-game-developers-to-follow"
      }}>{`several great game developers`}</a>{` in the space from whom we can draw inspiration.`}</p>
    <p>{`Another way is by bringing people of different backgrounds and skillsets together, via game jams and hackathons. `}<a parentName="p" {...{
        "href": "/track-gaming/module-1/curated/#cryptokitties-origin-story"
      }}>{`Cryptokitties`}</a>{` was famously the product of one in ETHWaterloo.`}</p>
    <p>{`These events are optimized towards finding the beginnings of a great idea. Each one is a focused unit of time, which starts with the promise of a blank slate, but is constrained just enough - by a theme and a deadline - to focus our efforts.`}</p>
    <p>{`In these environments, where we have to agree on a narrative with a diverse group of people, we are driven to create.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Separate the processes of creation from improving. You can’t write and edit, or sculpt and polish, or make and analyze at the same time. If you do, the editor stops the creator. While you invent, don’t select. While you sketch, don’t inspect. While you write the first draft, don’t reflect. At the start, the creator mind must be unleashed from judgement. - `}<Link to="https://web.archive.org/web/20220422203928/https://kk.org/thetechnium/68-bits-of-unsolicited-advice/" mdxType="Link">{`Kevin Kelly`}</Link></p>
    </blockquote>
    <h1 {...{
      "id": "-a-game-each-week",
      "style": {
        "position": "relative"
      }
    }}>{`🎮 A Game Each Week`}<a parentName="h1" {...{
        "href": "#-a-game-each-week",
        "aria-label": " a game each week permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://en.gameslol.net/adventure-capitalist-1086.html" mdxType="Link">
        <h4 {...{
          "id": "adventure-capitalist",
          "style": {
            "position": "relative"
          }
        }}>{`Adventure Capitalist`}<a parentName="h4" {...{
            "href": "#adventure-capitalist",
            "aria-label": "adventure capitalist permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`One of the pioneers of the idle game genre. Perhaps value can just be measured in clicks and 🍋s?`}</p>
        <Image src="/images/gaming-img/adcap.png" alt="Adventure Capitalist" mdxType="Image" />
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      